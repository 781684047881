

/*@font-face {*/
/*  font-family: 'ProximaBold';*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  src:  url('assets/fonts/Proxima Nova Bold.otf') format('otf');*/
/*  font-display: swap;*/

/*}*/

/*@font-face {*/
/*  font-family: 'ProximaThin';*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  src:  url('assets/fonts/Proxima Nova Thin.otf') format('otf');*/
/*  font-display: swap;*/

/*}*/

@font-face {
  font-family: 'ProximaRegular';
  src:  url('assets/fonts/ProximaNova-Regular.woff') format('woff');
  font-display: swap;

}

@font-face {
  font-family: 'Bodoni';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/OPTIBodoni-Antiqua.otf') format('otf');
  font-display: swap;

}




